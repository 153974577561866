import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';
import Amplify from 'aws-amplify';
import App from './App.vue';
import router from './router';
import store from './store';

// read configuration
import awsconfigDev from './configs/aws-exports.dev';
import awsconfigTst from './configs/aws-exports.tst';
import awsconfigPrd from './configs/aws-exports.prd';

const { origin } = window.location;
if (origin.includes('localhost') || origin.includes('127.0.0.1') || origin.includes('vmi.dev.mccolls.net.au')) {
  Amplify.configure(awsconfigDev);
} else if (origin.includes('vmi.tst.mccolls.net.au')) {
  Amplify.configure(awsconfigTst);
} else if (origin.includes('vmi.prd.mccolls.net.au')) {
  Amplify.configure(awsconfigPrd);
} else {
  throw new Error('Unable to load configuration');
}

applyPolyfills().then(() => {
  defineCustomElements(window);
});

const app = createApp(App);

app.use(store)
  .use(router)
  .use(ElementPlus);

app.mount('#app');
