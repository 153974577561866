
import axios from 'axios';
import moment from 'moment-timezone';
import _ from 'lodash';
import { Options, Vue } from 'vue-class-component';
import { LocationQuery, NavigationFailure } from 'vue-router';
import { ElMessage } from 'element-plus';
import { IApp } from '@/App.vue';
import { PipelineOverview } from '@/models/PipelineOverview';

@Options({
  watch: {
    async $route(to) {
      if (to.name !== 'Pipelines') {
        return;
      }
      this.updateFilter(to.query);
      await this.refresh();
    },
    async page() {
      await this.$router.push({ query: this.prepareData() });
    },
    async limit() {
      this.page = 1;
      await this.$router.push({ query: this.prepareData() });
    },
  },
})
export default class Pipelines extends Vue {
  readonly statusOptions = [
    { value: 'SUCCEEDED', label: 'SUCCEEDED' },
    { value: 'RUNNING', label: 'RUNNING' },
    { value: 'FAILED', label: 'FAILED' },
  ];

  readonly filterFormShortcuts = [
    {
      text: 'Last 24 hours',
      value: this.getLastXDays(1),
    },
    {
      text: 'Last 3 days',
      value: this.getLastXDays(3),
    },
    {
      text: 'Last 7 days',
      value: this.getLastXDays(7),
    },
  ];

  readonly limitOptions = [
    { value: 10 },
    { value: 20 },
    { value: 50 },
    { value: 100 },
  ];

  pipelineOptions = [];

  loading = false;

  itemsTotal = 0;

  page = 1;

  limit = 10;

  pipelines = [];

  filterForm: {pipelines: string[], status: string[], startedAt: Date[]} = {
    pipelines: [],
    status: [],
    startedAt: [],
  };

  async mounted(): Promise<void> {
    this.updateFilter();
    await this.loadPipelineFilter();
    await this.refresh();
  }

  async onRefresh(): Promise<void> {
    const currentQuery = this.$route.query;
    const newQuery = this.prepareData();
    if (_.isEqual(currentQuery, newQuery)) {
      await this.refresh();
    } else {
      await this.$router.push({ query: this.prepareData() });
    }
  }

  async changePage(newPage: number): Promise<void> {
    this.page = newPage;
    await this.$router.push({ query: this.prepareData() });
  }

  formatDate(value: string, format?: string): string {
    return moment(value).format(format || 'DD/MM/YYYY HH:mm:ss');
  }

  fromNow(value: string): string {
    return moment(value).fromNow();
  }

  async toDetails(subject: string): Promise<NavigationFailure | void | undefined> {
    return this.$router.push({ name: 'Details', query: { subject } });
  }

  private getLastXDays(day: number): Date[] {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * day);
    return [start, end];
  }

  private prepareData(): Record<string, string> {
    const resp: Record<string, string> = {
      page: `${this.page}`,
      limit: `${this.limit}`,
    };
    const { pipelines, status } = this.filterForm;
    if (pipelines.length > 0) {
      resp.pipelines = pipelines.join(',');
    }
    if (status.length > 0) {
      resp.status = status.join(',');
    }
    resp.between = `${moment(this.filterForm.startedAt[0]).unix()}`;
    resp.and = `${moment(this.filterForm.startedAt[1]).unix()}`;
    return resp;
  }

  private updateFilter(query?: LocationQuery): void {
    const q = query || this.$route.query;
    if (q.page) {
      this.page = parseInt(q.page as string, 10);
    } else {
      this.page = 1;
    }
    if (q.limit) {
      this.limit = parseInt(q.limit as string, 10);
    } else {
      this.limit = 100;
    }
    if (q.pipelines) {
      this.filterForm.pipelines = ((q.pipelines || '') as string).split(',');
    } else {
      this.filterForm.pipelines = [];
    }
    if (q.status) {
      this.filterForm.status = ((q.status || '') as string).split(',');
    } else {
      this.filterForm.status = [];
    }
    this.filterForm.startedAt = [
      this.$route.query.between
        ? moment.unix(parseInt((q.between as string), 10)).toDate()
        : moment().add(-7, 'days').utc().toDate(),
      this.$route.query.and
        ? moment.unix(parseInt((q.and as string), 10)).toDate()
        : moment().utc().toDate(),
    ];
  }

  private async loadPipelineFilter(): Promise<void> {
    try {
      this.loading = true;

      const resp = await axios.request({
        method: 'GET',
        baseURL: (this.$root as IApp).config?.apiUrlBase,
        url: '/pipelines/lookup',
        headers: {
          Authorization: `Bearer ${(this.$root as IApp).authToken}`,
        },
      });
      this.pipelineOptions = resp.data.map((d: PipelineOverview) => ({
        value: d.source, label: d.description,
      }));
    } catch (e) {
      ElMessage.error('Unable to get data on URL /pipelines/lookup');
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 300);
    }
  }

  private async refresh(): Promise<void> {
    try {
      this.loading = true;

      const query = this.prepareData();
      await this.$router.push({ query });

      const resp = await axios.request({
        method: 'GET',
        baseURL: (this.$root as IApp).config?.apiUrlBase,
        url: '/invocations',
        params: query,
        headers: {
          Authorization: `Bearer ${(this.$root as IApp).authToken}`,
        },
      });
      this.itemsTotal = Math.ceil(parseInt(resp.headers['x-total-count'], 10));
      this.pipelines = resp.data;
    } catch (e) {
      ElMessage.error('Unable to get data on URL /invocations');
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 300);
    }
  }
}
