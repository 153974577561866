<template>
  <amplify-auth-container>
    <amplify-authenticator>
      <amplify-sign-in slot="sign-in" hide-sign-up header-text="Sign In">
        <div slot="federated-buttons"></div>
      </amplify-sign-in>
      <div v-if="authState === 'signedin' && user">
        <el-container>
          <el-container>
            <router-view/>
          </el-container>
          <el-footer>
          </el-footer>
          <div style="display: flex; justify-content: end; padding: 20px;">
            <span style="display: inline-block">
              <amplify-sign-out></amplify-sign-out>
            </span>
          </div>
        </el-container>
      </div>
    </amplify-authenticator>
  </amplify-auth-container>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import appconfigDev from '@/configs/appsettings.dev';
import appconfigTst from '@/configs/appsettings.tst';
import appconfigPrd from '@/configs/appsettings.prd';

export interface IAppConfig {
  apiUrlBase?: string
}

export interface IApp extends Vue {
  config?: IAppConfig;
  authToken?: string;
}

@Options({
  components: {
  },
})
export default class App extends Vue implements IApp {
  user?: object;

  config?: IAppConfig;

  authToken?: string;

  authState: AuthState = AuthState.SignIn;

  unsubscribeAuth?: () => void

  created(): void {
    this.loadConfig();

    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
      this.authToken = (this.user as any).getSignInUserSession().getAccessToken().getJwtToken();
    });
  }

  beforeUnmount(): void {
    if (this.unsubscribeAuth) {
      this.unsubscribeAuth();
    }
  }

  private loadConfig() {
    const { origin } = window.location;
    if (origin.includes('localhost') || origin.includes('127.0.0.1') || origin.includes('vmi.dev.mccolls.net.au')) {
      this.config = appconfigDev;
    } else if (origin.includes('vmi.tst.mccolls.net.au')) {
      this.config = appconfigTst;
    } else if (origin.includes('vmi.prd.mccolls.net.au')) {
      this.config = appconfigPrd;
    } else {
      throw new Error('Unable to load configuration');
    }
    console.debug('Application configuration loaded', this.config);
  }
}
</script>

<style lang="scss">
:root {
  --amplify-primary-color: #409eff;
  --amplify-primary-tint: #409eff;
  --amplify-primary-shade: #409eff;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  margin: 0 auto;
}

aside {
  padding: 20px;
}
</style>
