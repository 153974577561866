<template>
  <el-main v-loading="loading">
    <el-card shadow="hover">
      <template #header>
        <div class="card-header">
          <div :title="pipelineEvent.source">{{ pipelineEvent.description }}</div>
          <el-button type="text" @click="$router.back()">Back</el-button>
        </div>
      </template>
      <dl>
        <dt>Execution ID</dt>
        <dd><el-tag type="info">{{ pipelineEvent.subject }}</el-tag></dd>
        <dt>Started At</dt>
        <dd>
          <el-tag type="info">
            {{ formatDate(pipelineEvent.created_at, 'DD/MM/YYYY HH:mm:ss.SSS') }}
          </el-tag>
        </dd>
        <dt>Updated At</dt>
        <dd>
          <el-tag type="info">
            {{ formatDate(pipelineEvent.updated_at, 'DD/MM/YYYY HH:mm:ss.SSS') }}
          </el-tag>
        </dd>
        <dt>Current State</dt>
        <dd>
          <el-tag v-if="pipelineEvent.status === 'SUCCEEDED'" type="success">
            {{pipelineEvent.status}}</el-tag>
          <el-tag v-if="pipelineEvent.status === 'RUNNING'" type="info">
            {{pipelineEvent.status}}</el-tag>
          <el-tag v-if="pipelineEvent.status === 'FAILED'" type="danger">
            {{pipelineEvent.status}}</el-tag>
        </dd>
      </dl>
      <p>Event Timeline</p>
      <el-timeline>
        <el-timeline-item v-for="(event, index) in sortEvents(pipelineEvent.events)"
          :key="index" :timestamp="formatDate(event.time, 'DD/MM/YYYY HH:mm:ss.SSS')">
          {{event.type}}: {{ event.data }}
        </el-timeline-item>
      </el-timeline>
      <dl>
        <dt>Raw Events Data</dt>
        <dd>
          <el-button type="text" @click="rawVisible = true">
            Click to show Raw Events Data
          </el-button>
        </dd>
      </dl>
    </el-card>
  </el-main>
  <el-dialog title="Raw Events Data" v-model="rawVisible" width="50%">
    <code>
      {{ pipelineEvent }}
    </code>
  </el-dialog>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import axios from 'axios';
import moment from 'moment-timezone';
import _ from 'lodash';
import { ElMessage } from 'element-plus';
import { IApp } from '@/App.vue';
import { Pipeline } from '@/models/Pipeline';
import { CloudSchema } from '@/generated-types/cloud-schema.d';

@Options({
  components: {
  },
})
export default class Details extends Vue {
  loading = false;

  rawVisible = false;

  pipelineEvent: Pipeline = {};

  async mounted(): Promise<void> {
    await this.refresh();
  }

  formatDate(value: string, format?: string): string {
    return moment(value).format(format || 'DD/MM/YYYY HH:mm:ss');
  }

  sortEvents(events: CloudSchema[]): CloudSchema[] {
    return _.sortBy(events, (event: CloudSchema) => event.time);
  }

  private async refresh(): Promise<void> {
    const { subject } = this.$route.query;
    if (!subject) {
      this.$router.back();
      return;
    }

    try {
      this.loading = true;
      const resp = await axios.request({
        method: 'GET',
        baseURL: (this.$root as IApp).config?.apiUrlBase,
        url: `/invocations/${subject}`,
        headers: {
          Authorization: `Bearer ${(this.$root as IApp).authToken}`,
        },
      });
      this.pipelineEvent = resp.data;
    } catch (e) {
      ElMessage.error(`Unable to get data on URL /invocations/${subject}`);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 300);
    }
  }
}
</script>

<style lang="scss" scoped>
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  :deep(dl) {
    margin-bottom: 1em;
    dt, dd {
      margin-bottom: 0.5em;
    }
  }
</style>
