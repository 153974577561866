import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Pipelines from '@/views/Pipelines.vue';
import Details from '@/views/Details.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Pipelines',
    component: Pipelines,
  },
  {
    path: '/details',
    name: 'Details',
    component: Details,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
