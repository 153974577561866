
import { Options, Vue } from 'vue-class-component';
import axios from 'axios';
import moment from 'moment-timezone';
import _ from 'lodash';
import { ElMessage } from 'element-plus';
import { IApp } from '@/App.vue';
import { Pipeline } from '@/models/Pipeline';
import { CloudSchema } from '@/generated-types/cloud-schema.d';

@Options({
  components: {
  },
})
export default class Details extends Vue {
  loading = false;

  rawVisible = false;

  pipelineEvent: Pipeline = {};

  async mounted(): Promise<void> {
    await this.refresh();
  }

  formatDate(value: string, format?: string): string {
    return moment(value).format(format || 'DD/MM/YYYY HH:mm:ss');
  }

  sortEvents(events: CloudSchema[]): CloudSchema[] {
    return _.sortBy(events, (event: CloudSchema) => event.time);
  }

  private async refresh(): Promise<void> {
    const { subject } = this.$route.query;
    if (!subject) {
      this.$router.back();
      return;
    }

    try {
      this.loading = true;
      const resp = await axios.request({
        method: 'GET',
        baseURL: (this.$root as IApp).config?.apiUrlBase,
        url: `/invocations/${subject}`,
        headers: {
          Authorization: `Bearer ${(this.$root as IApp).authToken}`,
        },
      });
      this.pipelineEvent = resp.data;
    } catch (e) {
      ElMessage.error(`Unable to get data on URL /invocations/${subject}`);
    } finally {
      setTimeout(() => {
        this.loading = false;
      }, 300);
    }
  }
}
