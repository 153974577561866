/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_zWUZjE8MS",
    "aws_user_pools_web_client_id": "3kcvvltfeikk0dba1pos8fl913",
    "oauth": {
        "domain": "vmi-mgmt-prd2.auth.ap-southeast-2.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "https://vmi.prd.mccolls.net.au/api/all",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "http://localhost:5050/callback,https://vmi.prd.mccolls.net.au/callback",
        "redirectSignOut": "http://localhost:5050/signout,https://vmi.prd.mccolls.net.au/signout",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
